<template>
  <div class="tab-wrap">
    <div class="columns is-justify-content-center is-align-items-center desktop-wrap">
      <button @click="goTo(stepLogin.url)" v-bind:class="{'selected': stepLogin.url === currentStep.url }" :disabled="stepLogin.url !== currentStep.url">
        Iniciar sesión
      </button>
      <button @click="goTo(stepDetail.url)" v-bind:class="{'selected': stepDetail.url === currentStep.url }" :disabled="stepDetail.url !== currentStep.url">
        Detalles orden
      </button>
      <!-- <button @click="goTo(stepShip.url)" v-bind:class="{'selected': stepShip.url === currentStep.url }" :disabled="stepShip.url !== currentStep.url">
        Envío
      </button>
      <button @click="goTo(stepPayment.url)" v-bind:class="{'selected': stepPayment.url === currentStep.url }" :disabled="stepPayment.url !== currentStep.url">
        Método de pago
      </button> -->
    </div>
    <div class="mobile-wrap">
      <button class="step-btn" @click="openStepWrap()">
        <label>{{currentStep.label}}</label>
        <span class="icon-chevron-down" v-if="!mobileStepOpen"></span>
        <span class="icon-chevron-up" v-if="mobileStepOpen"></span>
      </button>
      <transition name="fade">
        <div class="step-wrap" v-if="mobileStepOpen">
          <router-link :to="stepLogin.url" exact>Iniciar sesión</router-link>
          <router-link :to="stepDetail.url" exact>Detalles orden</router-link>
          <!-- <router-link :to="stepShip.url" exact>Envío</router-link>
          <router-link :to="stepPayment.url" exact>Método de pago</router-link> -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabWrap',
  props: {},
  data () {
    return {
      stepLogin: {
        label: 'Iniciar sesión',
        url: '/checkout/login'
      },
      stepDetail: {
        label: 'Detalles orden',
        url: '/checkout'
      },
      stepShip: {
        label: 'Envío',
        url: '/checkout/shipping'
      },
      stepPayment: {
        label: 'Método de pago',
        url: '/checkout/payment-methods'
      },
      currentStep: {
        label: '',
        url: ''
      },
      mobileStepOpen: false
    }
  },
  watch: {
    $route () {
      this.setCurrentStep()
    }
  },
  methods: {
    goTo (destination) {
      this.router.push(destination)
    },
    openStepWrap () {
      this.mobileStepOpen = !this.mobileStepOpen
    },
    setCurrentStep () {
      switch (this.$route.path) {
        case this.stepLogin.url:
          this.currentStep = this.stepLogin
          break
        case this.stepDetail.url:
          this.currentStep = this.stepDetail
          break
        case this.stepShip.url:
          this.currentStep = this.stepShip
          break
        case this.stepPayment.url:
          this.currentStep = this.stepPayment
          break
      }
    }
  },
  created () {
    this.setCurrentStep()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tab-wrap{
  width: 100%;
  background-color: #57567C;
  background-color: #F6F4EF;
  border-radius: 4px 4px 0px 0px;
  height: 66px;
  padding: 0px;
  .columns{
    height: 100%;
    &.desktop-wrap{}
    button{
      position: relative;
      display: block;
      height: 100%;
      background-color: transparent;
      border: 0px;
      color: #E3914F;
      text-transform: uppercase;
      font-weight: 600;
      min-width: 150px;
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0%;
        height: 2px;
        background-color: #E3914F;
        transition: all .3s ease-out;
      }
      &:hover{
        &:after{
          width: 10%;
        }
      }
      &.selected{
        &:after{
          width: 50%;
        }
      }
      &:disabled{
        opacity: .5;
        &:hover{
          &:after{
            width: 0%;
          }
        }
      }
    }
  }
  .mobile-wrap{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .tab-wrap{
    .columns{
      &.desktop-wrap{
        display: none;
      }
      button{
        width: 100%;
        &:disabled{
          display: none;
        }
      }
    }
    .mobile-wrap{
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      .step-btn{
        display: block;
        height: 100%;
        width: 100%;
        max-width: 320px;
        border: 0px;
        margin: 0 auto;
        background-color: transparent;
        color: #E3914F;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        span{
          position: absolute;
          top: 50%;
          right: 21px;
          transform: translate(0%, -50%);
          font-size: 1.5rem;
        }
      }
      .step-wrap{
        display: block;
        width: 100%;
        background-color: #F6F4EF;
        position: absolute;
        top: 100%;
        left: 0px;
        z-index: 99999;
        a{
          position: relative;
          display: block;
          height: 66px;
          width: 100%;
          max-width: 320px;
          margin: 0 auto;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          line-height: 66px;
          &:after{
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 0%;
            height: 2px;
            background-color: #E3914F;
            transition: all .3s ease-out;
          }
          &.router-link-active{
            &:after{
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
