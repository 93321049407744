<template>
  <div class="checkout-login">
    <section>
      <div class="container is-fullhd">
        <h3 class="title is-3 has-text-centered pb-6">Tramitar pedido</h3>
        <div class="checkout-wrap">
          <tab-wrap></tab-wrap>
          <div class="columns content-checkout-wrap">
            <clinic-login :callback="goTocart"></clinic-login>

            <div class="column is-5 register-wrap">
              <h4 class="title has-text-centered">Register</h4>
              <p class="is-size-6 has-text-centered">
                Founded in Madrid, STL(AB) strives to provide the best service for dental professionals. We believe a trusted network can become the key of your success. Reinvent your business workflow: fewer steps, more orders!
              </p>
              <button class="button is-primary is-fullwidth is-medium mt-4" @click="goTo('/clinic/login')">
                REGISTER
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import ClinicLogIn from '@/components/Clinic/ClinicLogIn.vue'
import Footer from '@/components/Footer.vue'
import TabWrap from '@/components/checkout/TabWrap.vue'

export default {
  name: 'CheckoutLogin',
  components: {
    Footer,
    TabWrap,
    'clinic-login': ClinicLogIn
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false
    }
  },
  methods: {
    goTocart () {
      this.goTo('/checkout')
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.checkout-login{
  width: 100%;
  section{
    width: 100%;
    padding: 60px 0px;
    .checkout-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-checkout-wrap{
        padding: 60px;
        .login-wrap, .register-wrap {
          padding: 0px 60px;
          padding-top: 24px;
        }
        .login-wrap{
          border-right: 1px solid #57567C;
          form{
            .recover-pwd{
              float: right;
              text-decoration: underline;
            }
            button{
              float: left;
              margin-top: 48px;
            }
          }
        }
        .register-wrap {
          position: relative;
          button{
            position: absolute;
            left: 60px;
            bottom: 0px;
            max-width: 354px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .checkout-login{
    section{
      .checkout-wrap{
        .tab-wrap{
          .columns{
            button{
              width: 100%;
              &:disabled{
                display: none;
              }
            }
          }
        }
        .content-checkout-wrap{
          padding: 35px;
          .login-wrap{
            border-right: 0px;
            overflow: hidden;
            form{
              button{
                display: block;
                line-height: 0px;
              }
            }
          }
          .register-wrap{
            overflow: hidden;
            button{
              position: initial;
              transform: initial;
              max-width: initial;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .checkout-login{
    section{
      .checkout-wrap{
        .content-checkout-wrap{
          .login-wrap{
            padding: 0 14px;
          }
          .register-wrap{
            margin-top: 40px;
            border-top: 1px solid #57567C;
            padding: 0 14px;
            padding-top: 25px;
          }
        }
      }
    }
  }
}
</style>
